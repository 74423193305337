import styled from 'styled-components';

import { fonts, palette } from '@/theme';
import DefInput from '@/components/Input';
import DefTextArea from '@/components/TextArea';
import Btn from '@/components/Button';

export const Container = styled.div`
  flex: 1 1 auto;
  display: flex;
  width: 100%;
  @media (max-width: 991px) {
    flex-direction: column;
  }
`;

export const Form = styled.form`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: 50%;
  border-right: 2px solid ${palette.black};
  @media (max-width: 991px) {
    width: 100%;
    border-right: none;
  }
`;

export const Wrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    flex: 0 0 auto;
  }
`;

export const Input = styled(DefInput)`
  & > input {
    border-top: ${({ error }) => (error ? 'none' : '2px solid')};
    border-color: ${palette.black};
  }
  @media (max-width: 991px) {
    & > input {
      border-width: 1px;
    }
  }
`;

export const TextArea = styled(DefTextArea)`
  flex: 1 1 auto;
  & > textarea {
    border-top: ${({ error }) => (error ? 'none' : '2px solid')};
    border-color: ${palette.black};
    margin-bottom: ${({ error }) => (error ? 2 : 0)}px;
  }
  @media (max-width: 991px) {
    & > textarea {
      border-width: 1px;
    }
  }
`;

export const InfoWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50%;
  padding: 8px 10px;
  & > div {
    align-self: flex-start;
    max-width: 50%;
  }
  & > div:first-child {
    margin-right: 20px;
  }
  @media (max-width: 991px) {
    width: 100%;
    flex-direction: column;
    & > div {
      max-width: 100%;
    }
    & > div:first-child {
      margin-right: 0;
      margin-bottom: 22px;
    }
  }
`;

export const Text = styled.p`
  ${fonts.text}
  padding: 15px 20px;
  @media (max-width: 991px) {
    ${fonts.smallText22}
    padding: 6px 10px;
  }
`;

export const InfoText = styled.p`
  ${fonts.smallText22}
`;

export const Link = styled.a`
  ${fonts.smallText22}
`;

export const Message = styled.p`
  ${fonts.smallText22}
  color: ${({ error }) => (error ? palette.red : palette.black)};
  padding: 4px;
`;

export const Button = styled(Btn)`
  border-top: 2px solid ${palette.black};
  @media (max-width: 991px) {
    border-top: 1px solid ${palette.black};
    border-bottom: 1px solid ${palette.black};
  }
`;
