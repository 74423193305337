/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { omit } from 'ramda';

import { useAsyncState, useObserver } from '@/hooks';
import { NAME, EMAIL, MESSAGE } from '@/server-api/consts';
import { useCreateContact } from '@/server-api/hooks';
import { extractYupErrors } from '@/utils';

import { validationSchema } from './validation';
import { Container, Form, Wrapper, Text, Input, TextArea, InfoWrap, InfoText, Link, Message, Button } from './styles';

const Contact = () => {
  const { contentJson } = useStaticQuery(
    graphql`
      query {
        contentJson(contact: { id: { eq: "contact" } }) {
          contact {
            address
            description
            links {
              href
              name
            }
            texts
            messages {
              success
              error
            }
          }
        }
      }
    `
  );
  const { description, messages, texts, address, links } = contentJson.contact;

  const { setPosition } = useObserver();

  const [values, setValues] = useAsyncState({});
  const [errors, setErrors] = useAsyncState({});
  const [{ loading, success, error }, createContact, reset] = useCreateContact();

  const handleChange = useCallback(
    (e) => {
      if (loading) return;
      if (success || error) reset();
      const { name, value } = e.target;

      setErrors(($) => omit([name], $));
      setValues(($) => ({ ...$, [name]: value }));
    },
    [error, loading, reset, setErrors, setValues, success]
  );
  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (success || error) reset();
      try {
        const data = await validationSchema.validate(values, { abortEarly: false });
        createContact(data);
      } catch (validateError) {
        setErrors(extractYupErrors(validateError));
      }
    },
    [createContact, error, reset, setErrors, success, values]
  );

  useEffect(() => {
    if (success) setValues({});
  }, [setValues, success]);

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Wrapper>
          <Text onTouchStart={setPosition} onMouseEnter={setPosition}>
            {description}
          </Text>
          <Input
            name={NAME}
            placeholder="Name"
            value={values[NAME] || ''}
            onChange={handleChange}
            onTouchStart={setPosition}
            onMouseEnter={setPosition}
            error={errors[NAME]}
          />
          <Input
            name={EMAIL}
            placeholder="E-Mail"
            value={values[EMAIL] || ''}
            onChange={handleChange}
            onTouchStart={setPosition}
            onMouseEnter={setPosition}
            error={errors[EMAIL]}
          />
          <TextArea
            name={MESSAGE}
            placeholder="Nachricht"
            value={values[MESSAGE] || ''}
            onChange={handleChange}
            onTouchStart={setPosition}
            onMouseEnter={setPosition}
            error={errors[MESSAGE]}
          />
        </Wrapper>
        <Button type="submit" onTouchStart={setPosition} onMouseEnter={setPosition} disabled={loading} primary>
          Senden
        </Button>
        {success && <Message>{messages.success}</Message>}
        {error && <Message error={error}>{messages.error}</Message>}
      </Form>
      <InfoWrap>
        <div onTouchStart={setPosition} onMouseEnter={setPosition}>
          {texts.map((text, key) => (
            <InfoText key={key}>{text}</InfoText>
          ))}
        </div>
        <div onTouchStart={setPosition} onMouseEnter={setPosition}>
          {address.map((string, key) => (
            <InfoText key={key}>{string}</InfoText>
          ))}
          {links.map(({ name, href }, key) => (
            <Fragment key={key}>
              <Link key={key} href={href} target="_blank" rel="noreferrer nofollow">
                {name}
              </Link>
              <br />
            </Fragment>
          ))}
          <br />
        </div>
      </InfoWrap>
    </Container>
  );
};

export default Contact;
