import styled from 'styled-components';

import { fonts, palette } from '@/theme';

const { black, white } = palette;

const Button = styled.button`
  width: 100%;
  height: 60px;
  background-color: ${({ primary }) => (primary ? black : white)};
  color: ${({ primary }) => (primary ? white : black)};
  ${fonts.text}
  &:active {
    background-color: ${white};
    color: ${black};
    box-shadow: inset 0 0 0 3px ${palette.black};
  }
  @media (hover) {
    &:hover {
      background-color: ${white};
      color: ${black};
      box-shadow: inset 0 0 0 3px ${palette.black};
    }
  }
  @media (max-width: 991px) {
    height: 30px;
    ${fonts.smallText22}
    &:active {
      background-color: ${white};
      color: ${black};
      box-shadow: inset 0 0 0 2px ${palette.black};
    }
  }
  @media (max-width: 991px) and (hover) {
    &:hover {
      background-color: ${white};
      color: ${black};
      box-shadow: inset 0 0 0 2px ${palette.black};
    }
  }
`;

export default Button;
