/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Container, Area } from './styles';

const TextArea = ({ error, className, placeholder, onFocus, onBlur, ...rest }) => {
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = useCallback(
    (e) => {
      onFocus(e);
      setIsFocused(true);
    },
    [onFocus]
  );
  const handleBlur = useCallback(
    (e) => {
      onBlur(e);
      setIsFocused(false);
    },
    [onBlur]
  );

  return (
    <Container className={className} placeholder={placeholder} error={error} isFocused={isFocused}>
      <Area error={error} placeholder={placeholder} onFocus={handleFocus} onBlur={handleBlur} {...rest} />
    </Container>
  );
};

TextArea.defaultProps = {
  error: null,
  className: '',
  onFocus: () => null,
  onBlur: () => null,
};
TextArea.propTypes = {
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  className: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

export default TextArea;
